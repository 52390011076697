/* eslint-disable import/prefer-default-export */
import {css} from '@emotion/core';

export const globalStyles = css`
    @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap');

    html {
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    * {
        font-family: 'Raleway', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
            Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        color: #3b425b;
        font-weight: 400;
    }

    html,
    body,
    main,
    #___gatsby,
    #gatsby-focus-wrapper {
        height: 100%;
        margin: 0;
    }

    h1 {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        margin: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
        margin: 0;
    }

    a {
        text-decoration: none;
        color: #3b425b;
    }

    p {
        line-height: 1.6;
        margin: 0;
        color: #3b425b;
    }
`;

export const chromeExtensionLink =
    'https://chrome.google.com/webstore/detail/mapquest/nlnkpbkccfjknplknbfnochlodjefnmf';
export const chromeDefaultSearchExtensionLink =
    'https://chrome.google.com/webstore/detail/mapquest-directions-searc/odcjkmhkmipghccecfflakpcppbdcjmb';
export const defaultSearchLandingPageOfferLink = 'https://apps.mapquest.com/directions-search/offer';
export const defaultSearchLandingPageLink = 'https://apps.mapquest.com/directions-search';
export const newTabLandingPageOfferLink = 'https://apps.mapquest.com/new-tab/offer';
export const successRedirectLink = 'https://www.kqzyfj.com/click-8402382-14103011?sid=mapquestext';
export const successRedirectLinkStartPage = 'https://add.startpage.com/?campaign=1&source=mapquestext&date=2020-05-01';
// google analytics ids
export const GA = {
    GTM: 'GTM-TFKLQXP',
    GA: 'UA-109615479-2'
};
