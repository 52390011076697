/* @noflow */
import {getUrlParameterByName} from './url';

const MARKETING_PARAMS = ['source', 'channel', 'campaign', 'adgroup', 'keyword', 'gclid'];

const APP_STORE_PARAMS = ['installdate', 'ext-name', 'ext-version', 'ext-id', 'browser', 'device-id'];

export const getAllMarketingParams = (experimentId = '') => {
    return MARKETING_PARAMS.reduce(
        (acc, param) => {
            acc[param] = getUrlParameterByName(param) || '';
            return acc;
        },
        {experimentId}
    );
};

export const getAppStoreParams = (params = APP_STORE_PARAMS) => {
    return params.reduce((acc, param) => {
        const camelCaseParam = param.replace(/-([a-z])/, (match, group1) => group1.toUpperCase());
        acc[camelCaseParam] = getUrlParameterByName(param) || '';
        return acc;
    }, {});
};

export const setExtensionParamsToLocalStorage = (experimentId) => {
    return new Promise((resolve) => {
        localStorage.clear();
        const extensionParams = getAllMarketingParams(experimentId);
        localStorage.setItem('extMeta', JSON.stringify(extensionParams));
        resolve();
    });
};

export const getExtensionParamsFromLocalStorage = () => {
    const extMeta = localStorage.getItem('extMeta');
    if (extMeta) {
        return JSON.parse(extMeta);
    }
    return null;
};
