/* @noflow */
/* eslint-disable */
/* global dataLayer */
import {mapquest as DPL} from '@s1/dpl';

import {getAllMarketingParams, getExtensionParamsFromLocalStorage} from './extensionMetadata';
import {GA} from '../constants';

// TODO @potench add the "name" to each event in DPL for ease of use
const {
    events: {mapquest: mqDpl}
} = DPL; // export events

Object.keys(mqDpl).forEach((key) => {
    mqDpl[key].name = key;
});
export const EVENTS = mqDpl;

const dataLayer = (global.dataLayer = global.dataLayer || []);

export const logDpl = async (tracker = EVENTS.MapquestExtensionLandingPageVisit, payload = {}) => {
    const marketingParams = getAllMarketingParams() || {};
    const finalPayload = {
        ...marketingParams,
        ...payload // it's important that this comes second so that payload k/v pairs can override marketingParams
    };

    dataLayer.push({
        event: tracker.name, // required
        ...finalPayload
    });

    return tracker.log(finalPayload);
};

export const logDplWithExtensionParams = (tracker = EVENTS.MapquestExtensionSuccessPageVisit, payload = {}) =>
    new Promise((resolve) => {
        const {experimentId} = payload;
        const defaultPayload = getAllMarketingParams(experimentId);
        const extMeta = getExtensionParamsFromLocalStorage();

        if (extMeta.installDate !== undefined) {
            extMeta['installdate'] = extMeta.installDate;
            delete extMeta.installDate;
        }

        const finalPayload = extMeta ? {...payload, ...extMeta} : {...payload, ...defaultPayload};
        const clientEvent = tracker.log(finalPayload);
        dataLayer.push({
            ...finalPayload,
            event: tracker.name, // required
            eventTimeout: 2000,
            eventCallback: () => {
                resolve(clientEvent);
            }
        });
    });
