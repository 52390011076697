import React, {FC, useEffect} from 'react';
import {navigate} from 'gatsby';
import {css} from '@emotion/core';
import {useLogging} from '../../../common/hooks/useLogging';
import Redirect from '../util/redirect';
// @ts-expect-error
import {getAppStoreParams} from '../util/extensionMetadata.js';
// @ts-expect-error
import {logDplWithExtensionParams, EVENTS} from '../util/dpl.js';
import {useChannel} from '../../../common/hooks/useChannel';

type FollowOnProps = {
    offerLink: string;
};

const SuccessRedirect: FC<FollowOnProps> = ({offerLink}) => {
    const {InstallEvent} = useLogging();
    const channel = useChannel();

    useEffect(() => {
        const appStoreParams = getAppStoreParams() || {};
        const isPrimaryOffer = channel === '1';

        logDplWithExtensionParams(EVENTS.MapquestExtensionSuccessPageVisit, appStoreParams).then(() => {
            InstallEvent(isPrimaryOffer).then(() => {
                if (isPrimaryOffer) {
                    navigate(offerLink, {state: {channel: '2'}});
                } else {
                    Redirect('https://www.mapquest.com');
                }
            });
        });
    }, [InstallEvent, channel, offerLink]);

    return (
        <div
            css={css`
                padding: 1rem;
            `}
        >
            loading...
        </div>
    );
};

export default SuccessRedirect;
